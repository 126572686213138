/**
 * Ex Server
 * 1.0.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from "oazapfts/lib/runtime";
import * as QS from "oazapfts/lib/runtime/query";
export const defaults: Oazapfts.RequestOpts = {
  baseUrl: "http://localhost:8880/ex",
};
const oazapfts = Oazapfts.runtime(defaults);
export const servers = {
  localApiGateway: "http://localhost:8880/ex",
  gcpDevApiGateway: "https://api.haiidev.co.kr/ex",
  gcpProductionApiGateway: "https://api.haii.io/ex",
};
export type Schema = {
  code: number;
  message?: string;
};
export type V1UserDetail = object;
export type V1UserCreateDelegatorRequest = {
  mandatary_uuid: string;
  name: string;
};
export type V1UserDelegatorAtCode = {
  atcode: string;
  expire: number;
};
export type V1VersionData = {
  ad?: boolean;
  latest: string;
  required: string;
};
export type V1NoticeData = {
  date: number;
  level?: number;
  thumbnail?: string;
  title: string;
  content?: string;
  meta?: object;
};
export type V1LicenseIssueRequest = {
  issuer?: string;
  product: number;
  item: number;
  issued?: number;
  expire: number;
  vCode?: string;
};
export type V1LicenseDetail = {
  lCode: string;
  version: number;
  issuer: string;
  product: number;
  item: number;
  issued: number;
  expire: number;
  vCode?: string;
};
export type V1MailSendRequest = {
  svc: string;
  to: string;
  template: string;
  values: {
    [key: string]: string;
  };
};
/**
 * 사용자 정보
 */
export function v1UserMe(opts?: Oazapfts.RequestOpts) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: Schema & {
          data?: V1UserDetail;
        };
      }
    | {
        status: 204;
      }
    | {
        status: number;
        data: {
          code: number;
          message?: string;
        };
      }
  >("/v1/user/me", {
    ...opts,
  });
}
/**
 * 위임인 생성
 */
export function v1UserDelegateCreate(
  v1UserCreateDelegatorRequest: V1UserCreateDelegatorRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: Schema & {
          data?: V1UserDetail;
        };
      }
    | {
        status: 204;
      }
    | {
        status: number;
        data: Schema;
      }
  >(
    "/v1/user/delegate/create",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: v1UserCreateDelegatorRequest,
    })
  );
}
/**
 * 위임자인 로그인 코드 생성
 */
export function v1UserCreateDelegateAtCode(
  uuid: string,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: Schema & {
          data?: V1UserDelegatorAtCode;
        };
      }
    | {
        status: 204;
      }
    | {
        status: number;
        data: Schema;
      }
  >(`/v1/user/delegate/atcode/${uuid}`, {
    ...opts,
  });
}
/**
 * 버전 체크
 */
export function v1ServiceVersion(
  svc: string,
  platform: string,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: V1VersionData;
      }
    | {
        status: 204;
      }
    | {
        status: number;
        data: Schema;
      }
  >(
    `/v1/service/${svc}/version${QS.query(
      QS.form({
        platform,
      })
    )}`,
    {
      ...opts,
    }
  );
}
/**
 * 공지
 */
export function v1ServiceStatus(svc: string, opts?: Oazapfts.RequestOpts) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: V1NoticeData;
      }
    | {
        status: 204;
      }
    | {
        status: number;
        data: Schema;
      }
  >(`/v1/service/${svc}/status`, {
    ...opts,
  });
}
/**
 * 공지
 */
export function v1ServiceNotice(
  svc: string,
  {
    limit,
    offset,
  }: {
    limit?: number;
    offset?: number;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: Schema & {
          data?: V1NoticeData[];
        };
      }
    | {
        status: 204;
      }
    | {
        status: number;
        data: Schema;
      }
  >(
    `/v1/service/${svc}/notice${QS.query(
      QS.form({
        limit,
        offset,
      })
    )}`,
    {
      ...opts,
    }
  );
}
/**
 * 라이센스 발급
 */
export function v1LicenseIssue(
  v1LicenseIssueRequest: V1LicenseIssueRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: Schema & {
          data?: V1LicenseDetail;
        };
      }
    | {
        status: 204;
      }
    | {
        status: number;
        data: Schema;
      }
  >(
    "/v1/license/issue.do",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: v1LicenseIssueRequest,
    })
  );
}
/**
 * 메일 발송
 */
export function v1MailSend(
  v1MailSendRequest: V1MailSendRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: Schema;
      }
    | {
        status: 204;
      }
    | {
        status: number;
        data: Schema;
      }
  >(
    "/v1/mail/send.do",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: v1MailSendRequest,
    })
  );
}
