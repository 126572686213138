import mitt, { Emitter } from "mitt";
import { VueConstructor } from "vue";

declare module "vue/types/vue" {
  interface Vue {
    $mitt: Emitter<{
      agree: void;
      next: void;
      toMainFromChat: void;
      goBack: void;
      changeLoadingFlag: boolean;
      checkEmergencyFinishedSplash: void;
      checkEmergencyFinishedMain: void;
      checkEmergencyFinishedAuthorizationGuide: void;
      checkEmergencyFinishedExaminationSetting: void;
      checkEmergencyFinishedKoss: void;
      "checkEmergencyFinishedPHQ-9": void;
      "checkEmergencyFinishedGAD-7": void;
      "checkEmergencyFinishedADNM-4": void;
      "checkEmergencyFinishedPC-PTSD-5": void;
      "checkEmergencyFinishedISI-K": void;
      checkEmergencyFinishedCSS: void;
      checkEmergencyFinishedOutro: void;
      checkEmergency: string;
      error: { code: number; severity: number; path?: string };
    }>;
  }
}

export const mittUse = mitt();

const emitter = {
  install(Vue: VueConstructor): void {
    Vue.prototype.$mitt = mittUse;
  },
};

export default emitter;
