import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import emitter from "@/plugins/mitt";
import haii from "@/plugins";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { firebaseConfigDev, firebaseConfigProd } from "@/plugins/firebase";
import "@/plugins/toasted";
import "@/registerServiceWorker";
import "@/assets/default.scss";

Vue.use(haii);
Vue.use(emitter);

Vue.config.productionTip = false;

const app = initializeApp(
  process.env.NODE_ENV === "production" ? firebaseConfigProd : firebaseConfigDev
);
const analytics = getAnalytics(app);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
