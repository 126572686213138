import { RootState, state } from "./state";
import { GetterTree } from "vuex";
import { TLicenseAppendix } from "@/types/licenseTypes";
import { V1AssessmentData } from "@/generated/api";

export const getters: GetterTree<typeof state, RootState> = {
  getLoading: (state: RootState): boolean => state.loading,
  getSendData: (state: RootState): boolean => state.sendData,
  getAuth: (state: RootState): string => state.auth,
  getPreventClose: (state: RootState): boolean => state.preventClose,
  getIsRefresh: (state: RootState): boolean => state.isRefresh,
  getPreventGoBack: (state: RootState): boolean => state.preventGoBack,
  getICode: (state: RootState): string => state.license.iCode,
  getLCode: (state: RootState): string => state.license.lCode,
  getAgentType: (state: RootState): string => state.license.agentType,
  getLicenseAppendix: (state: RootState): TLicenseAppendix =>
    state.license.licenseAppendix,
  getEmergencyChecked: (state: RootState): boolean => state.emergency.isChecked,
  getIsEmergency: (state: RootState): boolean => state.emergency.isEmergency,
  getIgnoreEmergencyUid: (state: RootState): string =>
    state.emergency.ignoreUid,
  getIsExceptional: (state: RootState): boolean =>
    state.emergency.isExceptional,
  getUserNickname: (state: RootState): string => state.userInfo.nickName,
  getUserNcs: (state: RootState): boolean => state.userInfo.ncs,
  getUserSf: (state: RootState): object => state.userInfo.sf,
  getUserJobCategory: (state: RootState): object => state.userInfo.jobCategory,
  getTotalSteps: (state: RootState): number => state.stepInfo.totalSteps,
  getCurrentStep: (state: RootState): number => state.stepInfo.currentStep,
  getChatContent: (state: RootState): object => state.chat.content,
  getCssScreening: (state: RootState): boolean => state.chat.cssScreening,
  getChatAssessment: (
    state: RootState
  ): {
    [key: string]: V1AssessmentData;
  } => state.chat.assessment,
  getAdnm4EventList: (state: RootState): object[] => state.adnm4EventList,
};

export type Getters = typeof getters;
