
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import { MutationTypes } from "@/store/mutations";

@Component({ components: {} })
export default class NoticeDialogCenter extends Vue {
  @Prop({ type: String, default: "" }) uid?: string;
  @Prop({ type: String, default: "" }) title?: string;
  @Prop({ type: String, default: "" }) additionalContent1?: string;
  @Prop({ type: String, default: "" }) additionalContent2?: string;
  @Prop({ type: String, default: "" }) content?: string;
  @Prop({ type: Number, default: -1 }) severity?: number;
  @Prop({ type: String, default: "" }) path?: string;

  @VModel({ type: Boolean, default: false })
  dialog?: boolean;

  exceptionalCommend = [] as string[];
  commendLength = 0;

  checkCommend() {
    if (this.commendLength < 4) return;

    const count = this.commendLength;
    const totalCommend =
      this.exceptionalCommend[count - 4] +
      this.exceptionalCommend[count - 3] +
      this.exceptionalCommend[count - 2] +
      this.exceptionalCommend[count - 1];
    if (totalCommend === "lrlr") {
      this.$store.commit(MutationTypes.EMERGENCY_IS_EXCEPTIONAL_SET, true);
      const routeName = this.$route.name;
      // TODO: any 수정
      const emitType = `checkEmergencyFinished${routeName}` as any;
      this.$mitt.emit(emitType);
      this.dialog = false;
    }
  }

  onClickLeft() {
    if (this.commendLength < 1) this.startTimer();
    this.exceptionalCommend.push("l");
    this.commendLength++;
    this.checkCommend();
  }

  onClickRight() {
    if (this.commendLength < 1) this.startTimer();
    this.exceptionalCommend.push("r");
    this.commendLength++;
    this.checkCommend();
  }
  onClick() {
    this.dialog = false;

    // 긴급 공지의 경우 splash 화면에서 정지
    if (this.severity == 0) {
      if (!this.$store.getters.getIsExceptional && this.$route.path !== "/") {
        this.$store.commit(MutationTypes.AUTH_SET, "");
        this.$router.replace("/");
      }
      return;
    }

    if (!this.path) return;

    if (this.path === "/method")
      this.$store.commit(MutationTypes.AUTH_SET, true);

    this.$store.commit(MutationTypes.AUTH_SET, this.path);
    this.$router.replace(this.path);
  }

  ignoreEmergency() {
    // "다시 보지 않기" 처리를 위해 local storage에 uid 저장
    this.$store.commit(MutationTypes.EMERGENCY_IGNORE_UID_SET, this.uid);
    this.onClick();
  }

  async startTimer() {
    await this.$_haii.util.sleep(5000);
    this.exceptionalCommend = [];
    this.commendLength = 0;
  }
}
