class util {
  static sleep(time: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, time);
    });
  }

  static timer = 0;
  static debounce(callback: any, delay: number) {
    if (util.timer) {
      clearTimeout(util.timer);
    }
    util.timer = setTimeout(callback, delay);
  }
}

export default util;
