
import { Component, Vue, Watch } from "vue-property-decorator";
import { getAnalytics, logEvent } from "firebase/analytics";
import NoticeDialogCenter from "@/components/common/util/NoticeDialogCenter.vue";
import { V1NoticeItem } from "@/generated/api";
import { MutationTypes } from "@/store/mutations";
import { viewComponentType } from "@/router";

@Component({ components: { NoticeDialogCenter } })
export default class App extends Vue {
  analytics = getAnalytics();
  alertFlag = false;
  uid = "";
  title = "";
  content = "";
  severity = -1;
  emergency = {} as V1NoticeItem;

  setScreenSize() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  handleWebError(errorCode: number) {
    let comment =
      "불편을 드려 죄송합니다.\n해당 사용권 코드로 오류가 발생하였습니다.\n";
    switch (errorCode) {
      case 99901:
        comment += "입력하신 주소를 다시 확인해 주시길 바랍니다.\n";
        break;
      case 99902:
        comment += "해당 사용권 코드는 사용이 취소되었습니다.\n";
        break;
      case 99903:
        comment += "해당 사용권 코드는 사용이 취소되었습니다.\n";
        break;
      case 99904:
        comment += "해당 사용권 코드는 이미 사용되었습니다.\n";
        break;
      case 99905:
        comment += "정보를 불러올 수 없습니다.\n";
        break;
      default:
        break;
    }
    return comment + "문제가 지속될 시 고객센터로 문의 바랍니다.";
  }

  handleError(errorInfo: { code: number; severity: number }) {
    this.title = `[CODE: ${errorInfo.code}]`;
    this.severity = errorInfo.severity;

    const separationCode = Math.floor(errorInfo.code / 100) % 100;
    switch (separationCode) {
      case 0:
        this.content =
          "불편을 드려 죄송합니다.\n해당 사용권 코드로 오류가 발생하였습니다.\n문제가 지속될 시 고객센터로 문의 바랍니다.";
        break;
      case 1:
        this.content =
          "불편을 드려 죄송합니다.\n해당 사용권 코드로 오류가 발생하였습니다.\n문제가 지속될 시 고객센터로 문의 바랍니다.";
        break;
      case 8:
        this.content =
          "불편을 드려 죄송합니다.\n네트워크에 연결할 수 없습니다.\n문제가 지속될 시 고객센터로 문의 바랍니다.";
        break;
      case 99:
        this.content = this.handleWebError(errorInfo.code);
        break;
      default:
        this.content =
          "불편을 드려 죄송합니다.\n현재 오류가 발생했습니다.\n문제가 지속될 시 고객센터로 문의 바랍니다.";
        break;
    }
    this.alertFlag = true;
    this.$_changeLoadingState(false);
  }

  async getExServiceStatus() {
    // 서버 상태 확인
    const serviceStatus = await this.$_haii.exServer.v1ServiceStatus();
    if (serviceStatus?.code !== 200 && serviceStatus?.code !== 204) {
      console.log(serviceStatus);
      this.handleError({ code: 99801, severity: 0 });
      return;
    }
  }

  async getEmergencyData() {
    // 공지사항 여부 데이터 확인
    const res = await this.$_haii.webServer.v1NoticeEmergency();

    if (typeof res === "string") {
      const errorInfo = JSON.parse(res);
      this.handleError({ code: errorInfo?.code ?? 500, severity: 0 });
      return;
    }

    this.emergency = res;
  }

  async getAlertData(routeName: string) {
    await this.getEmergencyData();

    if (!this.emergency?.uid) return false;

    const ignoreUid = this.$store.getters.getIgnoreEmergencyUid;

    // 다시 보지 않기 처리한 공지 무시
    if (ignoreUid == this.emergency.uid) return false;

    this.uid = this.emergency.uid ?? "";
    this.title = this.emergency.title ?? "";
    this.content = this.emergency.contents ?? "";
    this.severity = this.emergency.severity ?? -1;

    // 검진 중에는 긴급공지가 아닐 경우 띄우지 않음
    if (routeName !== "Splash" && routeName !== "Main" && this.severity !== 0)
      return false;
    return true;
  }

  async checkEmergency(routeName: string) {
    const isAlert = await this.getAlertData(routeName);
    const isExceptional = this.$store.getters.getIsExceptional;
    this.alertFlag = !isExceptional && isAlert;

    this.$store.commit(MutationTypes.EMERGENCY_CHECKED_SET, true);
    this.$store.commit(MutationTypes.IS_EMERGENCY_SET, this.severity === 0);

    if (routeName === "Splash" && this.alertFlag) {
      if (this.severity === 0) this.$mitt.emit("changeLoadingFlag", false);
      return;
    }

    // TODO: any 수정
    const emitType = `checkEmergencyFinished${routeName}` as any;
    this.$mitt.emit(emitType);
  }

  @Watch("$route")
  screenView() {
    if (this.$route.name === "Main") return;
    logEvent(this.analytics, "screen_view" as string, {
      firebase_screen: this.$route.name,
    });
  }

  @Watch("alertFlag")
  checkSplash() {
    const route = this.$route.name;
    if (route === "Splash" && !this.alertFlag && this.severity !== 0)
      this.$mitt.emit(viewComponentType.CHECK_EMERGENCY_FINISH_SPLASH);
  }

  created() {
    this.setScreenSize();
    window.addEventListener("resize", this.setScreenSize);
    this.$mitt.on("error", (errorData) => this.handleError(errorData));
    this.$mitt.on("checkEmergency", (routeName) =>
      this.checkEmergency(routeName)
    );
  }

  mounted() {
    this.$_haii.channelTalk.loadScript();
    this.$_haii.channelTalk.boot({
      pluginKey: process.env.VUE_APP_CHANNELTALK_PLUGIN_KEY,
      hideChannelButtonOnBoot: true,
    });
  }
}
