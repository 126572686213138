import * as Oazapfts from "oazapfts/lib/runtime";
import * as exOpenAPI from "@/generated/exApi";
import * as openAPI from "@/generated/api";
import { Schema, V1NoticeData, v1ServiceStatus } from "@/generated/exApi";
import { handle } from "oazapfts";
import {
  GenericResponse,
  V1AssessRequest,
  V1AssessResponse,
  v1BusinessIssue,
  V1LicenseIssueRequest,
  V1LicenseResponse,
  V1LicenseVerifyRequest,
  V1NcsJsonResponse,
  V1NoticeItem,
  V1ServiceJsonAdnm4EventsListResponse,
  V1ServiceJsonResponse,
} from "@/generated/api";

export type ResponseType = { status: number; data: any };

class ExClass {
  readonly baseUrl: string;

  constructor(apiSet: typeof exOpenAPI) {
    this.baseUrl = process.env.VUE_APP_EX_SERVER_URL;
  }

  protected get requestOptions(): Oazapfts.RequestOpts {
    return {
      baseUrl: this.baseUrl,
    };
  }

  async handleResponse(response: Error | ResponseType): Promise<string> {
    if (response instanceof Error) {
      console.log({
        apiError: true,
        message: response,
      });
      return response + "";
    }

    switch ((response.data as GenericResponse).code) {
      case 401:
        break;

      default:
        console.log({
          apiError: true,
          message: response.data,
        });
        break;
    }

    return response + "";
  }
}

export class ExServerClass extends ExClass {
  constructor() {
    super(exOpenAPI);
  }

  // 공지
  async v1ServiceStatus(
    opts?: Oazapfts.RequestOpts
  ): Promise<V1NoticeData | Schema | string | any> {
    try {
      return await handle(
        exOpenAPI.v1ServiceStatus("tess", this.requestOptions),
        {
          200(res) {
            return res;
          },
          204(res) {
            return res;
          },
          default(status, data) {
            return JSON.stringify(data);
          },
        }
      );
    } catch (e) {
      return e + "";
    }
  }
}

class ServerClass {
  readonly baseUrl: string;

  constructor(apiSet: typeof openAPI) {
    this.baseUrl = process.env.VUE_APP_SERVER_URL;
  }

  protected get requestOptions(): Oazapfts.RequestOpts {
    return {
      headers: {
        "X-App-Agent": "web",
        "X-App-Name":
          process.env.VUE_APP_ENV == "production"
            ? "io.haii.assess"
            : "io.haii.assess.dev",
        "X-App-Version":
          process.env.VUE_APP_ENV == "production" ? "1.3.1" : "1.3.1",
      },
      baseUrl: this.baseUrl,
    };
  }

  async handleResponse(response: Error | ResponseType): Promise<string> {
    if (response instanceof Error) {
      console.log({
        apiError: true,
        message: response,
      });
      return response + "";
    }

    switch ((response.data as GenericResponse).code) {
      case 401:
        break;

      default:
        console.log({
          apiError: true,
          message: response.data,
        });
        break;
    }

    return response + "";
  }
}

export class WebServerClass extends ServerClass {
  constructor() {
    super(openAPI);
  }

  // 라이센스 발급
  async v1LicenseIssue(
    v1LicenseIssueRequest: V1LicenseIssueRequest,
    opts?: Oazapfts.RequestOpts
  ): Promise<V1LicenseResponse | string> {
    try {
      return await handle(
        openAPI.v1LicenseIssue(v1LicenseIssueRequest, this.requestOptions),
        {
          200(res: GenericResponse & { data?: V1LicenseResponse }) {
            // return res.data;
            return res;
          },
          418(res: GenericResponse) {
            return JSON.stringify(res);
          },
          default(status: number, data) {
            return JSON.stringify({ status: status, data: data });
          },
        }
      );
    } catch (e) {
      return e + "";
    }
  }

  // b2b 라이센스 발급
  async v1BusinessIssue(
    org: string,
    div: string,
    uid: string,
    opts?: Oazapfts.RequestOpts
  ): Promise<V1LicenseResponse | string> {
    try {
      return await handle(
        openAPI.v1BusinessIssue(org, div, uid, this.requestOptions),
        {
          200(res: GenericResponse & { data?: V1LicenseResponse }) {
            // return res.data;
            return res;
          },
          418(res: GenericResponse) {
            return JSON.stringify(res);
          },
          default(status: number, data) {
            return JSON.stringify({ status: status, data: data });
          },
        }
      );
    } catch (e) {
      return e + "";
    }
  }

  // 라이센스 검증
  async v1LicenseVerify(
    v1LicenseVerifyRequest: V1LicenseVerifyRequest,
    opts?: Oazapfts.RequestOpts
  ): Promise<V1LicenseResponse | string> {
    try {
      return await handle(
        openAPI.v1LicenseVerify(v1LicenseVerifyRequest, this.requestOptions),
        {
          200(res: GenericResponse & { data?: V1LicenseResponse }) {
            // return res.data;
            return res;
          },
          418(res: GenericResponse) {
            return JSON.stringify(res);
          },
          default(status: number, data) {
            return JSON.stringify({ status: status, data: data });
          },
        }
      );
    } catch (e) {
      return e + "";
    }
  }

  // 라이센스 발급 정보 확인
  async v1LicenseNoAuthInfo(
    v1LicenseVerifyRequest: V1LicenseVerifyRequest,
    opts?: Oazapfts.RequestOpts
  ): Promise<V1LicenseResponse | string> {
    try {
      return await handle(
        openAPI.v1LicenseNoAuthInfo(
          v1LicenseVerifyRequest,
          this.requestOptions
        ),
        {
          200(res: GenericResponse & { data?: V1LicenseResponse }) {
            // return res.data;
            return res;
          },
          418(res: GenericResponse) {
            return JSON.stringify(res);
          },
          default(status: number, data) {
            return JSON.stringify({ status: status, data: data });
          },
        }
      );
    } catch (e) {
      return e + "";
    }
  }

  // 검진 결과 확인
  async v1NoAuthAssess(
    v1AssessRequest: V1AssessRequest,
    opts?: Oazapfts.RequestOpts
  ): Promise<V1AssessResponse | string> {
    try {
      return await handle(
        openAPI.v1NoAuthAssess(v1AssessRequest, this.requestOptions),
        {
          200(res: GenericResponse & { data?: V1AssessResponse }) {
            // return res.data;
            return res;
          },
          418(res: GenericResponse) {
            return JSON.stringify(res);
          },
          default(status: number, data) {
            return JSON.stringify({ status: status, data: data });
          },
        }
      );
    } catch (e) {
      return e + "";
    }
  }

  // 검진 json 받기
  async v1ServiceNoAuthJson(
    pCate: string,
    agentType: string,
    lCode: string,
    opts?: Oazapfts.RequestOpts
  ): Promise<V1ServiceJsonResponse | string> {
    try {
      return await handle(
        openAPI.v1ServiceNoAuthJson(
          pCate,
          agentType,
          lCode,
          this.requestOptions
        ),
        {
          200(res: GenericResponse & { data?: V1ServiceJsonResponse }) {
            // return res.data;
            return res;
          },
          418(res: GenericResponse) {
            return JSON.stringify(res);
          },
          default(status: number, data) {
            return JSON.stringify({ status: status, data: data });
          },
        }
      );
    } catch (e) {
      return e + "";
    }
  }

  // 적응 스트레스 사건 목록 조회
  async v1ServiceNoAuthJsonAdnm4EventsList(
    pCate: string,
    agentType: string,
    lCode: string,
    opts?: Oazapfts.RequestOpts
  ): Promise<V1ServiceJsonAdnm4EventsListResponse | string> {
    try {
      return await handle(
        openAPI.v1ServiceNoAuthJsonAdnm4EventsList(
          pCate,
          agentType,
          lCode,
          this.requestOptions
        ),
        {
          200(
            res: GenericResponse & {
              data?: V1ServiceJsonAdnm4EventsListResponse;
            }
          ) {
            return res;
          },
          418(res: GenericResponse) {
            return JSON.stringify(res);
          },
          default(status: number, data) {
            return JSON.stringify({ status: status, data: data });
          },
        }
      );
    } catch (e) {
      return e + "";
    }
  }

  // ncs 코드, 이름 조회
  async v1NcsJson(
    opts?: Oazapfts.RequestOpts
  ): Promise<V1NcsJsonResponse | string> {
    try {
      return await handle(openAPI.v1NcsJson(this.requestOptions), {
        200(res: GenericResponse & { data?: V1NoticeItem }) {
          // return res.data;
          return res;
        },
        418(res: GenericResponse) {
          return JSON.stringify(res);
        },
        default(status: number, data) {
          return JSON.stringify({ status: status, data: data });
        },
      });
    } catch (e) {
      return e + "";
    }
  }

  // 공지사항
  async v1NoticeEmergency(
    opts?: Oazapfts.RequestOpts
  ): Promise<V1NoticeItem | string> {
    try {
      return await handle(openAPI.v1NoticeEmergency(this.requestOptions), {
        200(res: GenericResponse & { data?: V1NoticeItem }) {
          // return res.data;
          return res;
        },
        418(res: GenericResponse) {
          return JSON.stringify(res);
        },
        default(status: number, data) {
          return JSON.stringify({ status: status, data: data });
        },
      });
    } catch (e) {
      return e + "";
    }
  }
}
