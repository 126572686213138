/**
 * TESS Server
 * 1.0.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from "oazapfts/lib/runtime";
import * as QS from "oazapfts/lib/runtime/query";
export const defaults: Oazapfts.RequestOpts = {
  baseUrl: "http://localhost:8880/tess",
};
const oazapfts = Oazapfts.runtime(defaults);
export const servers = {
  localApiGateway: "http://localhost:8880/tess",
  gcpDevApiGateway: "https://api.haiidev.co.kr/tess",
  gcpProductionApiGateway: "https://api.haii.io/tess",
  localDirect: "http://localhost:8884/api",
  gcpDevDirect: "https://haii-d-prj-tess.du.r.appspot.com/api",
};
export type V1LicenseIssueRequest = {
  iCode: string;
  expire?: number;
  enableCount?: number;
  waitingDays?: number;
};
export type GenericResponse = {
  code: number;
  message: string | object;
  data?: any;
};
export type V1LicenseDetail = {
  lCode: string;
  version?: number;
  issuer?: string;
  product?: number;
  item?: number;
  issued?: number;
  expire?: number;
  vCode?: string;
  iCode?: string;
  agentType?: string;
  appendix?: object;
  history?: string[];
  status?: number;
};
export type V1LicenseResponse = {
  license?: V1LicenseDetail;
};
export type V1LicenseVerifyRequest = {
  lCode: string;
  iCode?: string;
};
export type V1LicenseRegisterRequest = {
  lCode: string;
  iCode: string;
};
export type V1StatisticsFactors = object;
export type V1Dialog = {
  id: string;
  appendix?: string;
  q_ts: number;
  a_ts: number;
  answer: string;
  point?: number;
};
export type V1AssessmentData = {
  start_ts: number;
  end_ts: number;
  dialog: V1Dialog[];
};
export type V1Hrbpm = {
  ts: number;
  bpm: number;
  snr?: number;
};
export type V1HrvIndex = {
  lf?: number;
  lfp?: number;
  lnLf?: number;
  normLf?: number;
  hf?: number;
  hfp?: number;
  lnHf?: number;
  normHf?: number;
  vlf?: number;
  vlfp?: number;
  lnVlf?: number;
  lfHf?: number;
  tp?: number;
  lnTp?: number;
  sdnn?: number;
  rmssd?: number;
  pnn50?: number;
  cohRatio?: number;
  ppiCount?: number;
};
export type V1HrvData = V1Hrbpm & V1HrvIndex;
export type V1HrvMetaItem = {
  sigR?: number;
  sigG?: number;
  sigB?: number;
  time?: number;
  qualityCheck?: boolean;
};
export type V1HrvMeta = V1HrvMetaItem[];
export type V1HrvResult = {
  HF?: number[];
  HR?: number[];
  LF?: number[];
  MSI?: number[];
  PSI?: number[];
  RMSSD?: number[];
  SDNN?: number[];
  SDSD?: number[];
  TP?: number[];
  caution?: string;
  lnHF?: number[];
  lnLF?: number[];
  lnTP?: number[];
  meanRR?: number[];
  norm_HF?: number[];
  norm_LF?: number[];
  norm_VLF?: number[];
  pnn20?: number[];
  pnn50?: number[];
  TINN?: number[];
  calc_times?: number[];
};
export type V1HrvDeepData = {
  meta?: V1HrvMeta;
  result?: V1HrvResult;
};
export type V1AssessRequest = {
  date?: number;
  sf?: V1StatisticsFactors;
  assessment: {
    [key: string]: V1AssessmentData;
  };
  hrv?: V1HrvData[];
  hrv_deep?: {
    windowSec?: number;
    overlapSec?: number;
    data?: V1HrvDeepData;
  };
  license?: string;
};
export type EntityResponse = {
  UUID: string;
  CTS?: number;
  MTS?: number;
};
export type V1SymptomAssessmentResult = {
  score: number;
  level: number;
  riskRate?: number;
  description?: string;
  guidance?: string;
  advice?: number;
  treatment?: number;
};
export type V1KossAssessmentResultItem = {
  score: number;
  distribution: number[];
  subAverage?: number;
  subTotal?: number;
  riskRate?: number;
};
export type V1KossAssessmentResult = {
  [key: string]: V1KossAssessmentResultItem;
};
export type V1AssessmentResult = {
  date?: number;
  assessment: {
    [key: string]: V1SymptomAssessmentResult | V1KossAssessmentResult;
  };
};
export type V1AssessResponse = EntityResponse & {
  data: V1AssessmentResult;
};
export type V1HistoriesResponse = V1AssessmentResult[];
export type V1NoAuthHistoriesRequest = {
  iCode: string;
};
export type V1NoAuthHistoriesResponse = V1HistoriesResponse;
export type V1IntegrationAssessmentResultRequest = {
  iCode?: string;
  registrationNumber?: string;
  detail?: boolean;
};
export type V1IntegrationAssessmentResultResponse = {
  check: number;
  count: number;
  histories?: V1HistoriesResponse;
};
export type V1KmiDailyResult = {
  rgstno: string;
  instcd: string;
  testresult: string;
  testcode: string;
  testdate: string;
  flag: string;
};
export type V1IntegrationKmiDailyResultResponse = V1KmiDailyResult[];
export type V1IntegrationKmiReservationChangeRequest = {
  old: string;
  new?: string;
};
export type V1IntegrationKmiResultResponse = V1KmiDailyResult[];
export type V1IntegrationSalusCheckupsRequest = {
  checkupDivCd: string;
  centerCd: string;
  checkupRosterNo: string;
  checkupDe: string;
  roasterName: string;
  email: string;
  birthday: string;
  sexCd: string;
  mobileNo: string;
  rceptSttusCd: string;
};
export type V1IntegrationSalusCheckupsResponse = {
  statusCd: string;
  message: string;
};
export type V1B2BResultCheckResponse = {
  uid: string;
  status: -1 | 0 | 1;
};
export type V1AttendeeRegisterRequest = {
  name: string;
  birthyear: number;
  organization: string;
  orgCode: string;
  mobile: string;
  gender?: string;
  address?: string;
};
export type V1AttendeeRegisterResponse = {
  iCode?: string;
  name?: string;
  organization?: string;
  mobile?: string;
};
export type V1ServiceReviewCollectRequest = {
  type: string;
  value: object;
};
export type V1ServiceJsonResponse = object;
export type V1ServiceJsonAdnm4Event = {
  id: number;
  name: string;
};
export type V1ServiceJsonAdnm4EventsListResponse = V1ServiceJsonAdnm4Event[];
export type V1NcsJsonResponseNcsItem = {
  code?: string;
  name?: string;
  categories?: V1NcsJsonResponseNcsItem[];
};
export type V1NcsJsonResponse = {
  revision?: number;
  data?: {
    nonworker?: V1NcsJsonResponseNcsItem[];
    worker?: V1NcsJsonResponseNcsItem[];
  };
};
export type V1NoticeItem = {
  uid?: string;
  severity?: number;
  title?: string;
  contents?: string;
  meta?: object;
  startTimestamp?: number;
  endTimestamp?: number;
};
export type V1NoticeEmergencyResponse = V1NoticeItem;
export type V1MainInfoRequest = {
  lCode: string;
};
export type V1MainInfoResponse = {
  licenseStatusCode: number;
  description: string;
  resultFlag: boolean;
  resultCount?: number;
  enableTimestamp?: number;
  remainDays?: number;
};
/**
 * health check
 */
export function statusCheck(opts?: Oazapfts.RequestOpts) {
  return oazapfts.fetchText("/status", {
    ...opts,
  });
}
/**
 * 라이센스 발급
 */
export function v1LicenseIssue(
  v1LicenseIssueRequest: V1LicenseIssueRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse & {
          data?: V1LicenseResponse;
        };
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    "/v1/license/issue.do",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: v1LicenseIssueRequest,
    })
  );
}
/**
 * 라이센스 검증
 */
export function v1LicenseVerify(
  v1LicenseVerifyRequest: V1LicenseVerifyRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse & {
          data?: V1LicenseResponse;
        };
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    "/v1/license/verify.do",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: v1LicenseVerifyRequest,
    })
  );
}
/**
 * 라이센스 발급 정보 확인
 */
export function v1LicenseInfo(
  v1LicenseVerifyRequest: V1LicenseVerifyRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: V1LicenseResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    "/v1/license/info.do",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: v1LicenseVerifyRequest,
    })
  );
}
/**
 * 라이센스 등록
 */
export function v1LicenseRegister(
  v1LicenseRegisterRequest: V1LicenseRegisterRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: V1LicenseResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    "/v1/license/register.do",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: v1LicenseRegisterRequest,
    })
  );
}
/**
 * 라이센스 발급 정보 확인
 */
export function v1LicenseNoAuthInfo(
  v1LicenseVerifyRequest: V1LicenseVerifyRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse & {
          data?: V1LicenseResponse;
        };
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    "/v1/license/noauth/info.do",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: v1LicenseVerifyRequest,
    })
  );
}
/**
 * 검진 결과 확인
 */
export function v1Assess(
  v1AssessRequest: V1AssessRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: V1AssessResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    "/v1/assess.do",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: v1AssessRequest,
    })
  );
}
/**
 * 검진 결과 확인
 */
export function v1NoAuthAssess(
  v1AssessRequest: V1AssessRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse & {
          data?: V1AssessResponse;
        };
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    "/v1/noauth/assess.do",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: v1AssessRequest,
    })
  );
}
/**
 * 검진 결과 내역
 */
export function v1Histories(
  {
    pagination,
  }: {
    pagination?: any;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: V1HistoriesResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/histories${QS.query(
      QS.form({
        pagination,
      })
    )}`,
    {
      ...opts,
    }
  );
}
/**
 * 검진 결과 조회
 */
export function v1NoAuthHistories(
  v1NoAuthHistoriesRequest: V1NoAuthHistoriesRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse & {
          data?: V1NoAuthHistoriesResponse;
        };
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    "/v1/noauth/histories",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: v1NoAuthHistoriesRequest,
    })
  );
}
/**
 * 검진 결과 확인
 */
export function v1IntegrationAssessResult(
  v1IntegrationAssessmentResultRequest: V1IntegrationAssessmentResultRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: V1IntegrationAssessmentResultResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    "/v1/i/result",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: v1IntegrationAssessmentResultRequest,
    })
  );
}
/**
 * 검진 결과 확인
 */
export function v1IntegrationKmiDailyResultResponse(
  {
    date,
  }: {
    date?: string;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: V1IntegrationKmiDailyResultResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/i/kmi/daily_result${QS.query(
      QS.form({
        date,
      })
    )}`,
    {
      ...opts,
    }
  );
}
/**
 * 예약 변경 동기화
 */
export function v1IntegrationReservationChange(
  v1IntegrationKmiReservationChangeRequest: V1IntegrationKmiReservationChangeRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    "/v1/i/kmi/res_change.do",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: v1IntegrationKmiReservationChangeRequest,
    })
  );
}
/**
 * 검진 결과 요약 조회
 */
export function v1IntegrationKmiResult(
  rgstno: string,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: V1IntegrationKmiResultResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/i/kmi/result${QS.query(
      QS.form({
        rgstno,
      })
    )}`,
    {
      ...opts,
    }
  );
}
/**
 * 보고서 (재)전송 요청하기
 */
export function v1IntegrationReport(
  aid: string,
  {
    q,
  }: {
    q?: string;
  } = {},
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/i/report${QS.query(
      QS.form({
        aid,
        q,
      })
    )}`,
    {
      ...opts,
    }
  );
}
/**
 * 검진 접수
 */
export function v1IntegrationSalusCheckups(
  v1IntegrationSalusCheckupsRequest: V1IntegrationSalusCheckupsRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: V1IntegrationSalusCheckupsResponse;
      }
    | {
        status: 418;
        data: V1IntegrationSalusCheckupsResponse;
      }
  >(
    "/v1/i/salus/checkups",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: v1IntegrationSalusCheckupsRequest,
    })
  );
}
/**
 * b2b 기업 기본형 연동 라이센스 발급
 */
export function v1BusinessIssue(
  org: string,
  div: string,
  uid: string,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse & {
          data?: V1LicenseResponse;
        };
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(`/v1/b/issue/${org}/${div}/${uid}`, {
    ...opts,
    method: "POST",
  });
}
/**
 * b2b 기업 간 수행 결과 조회
 */
export function v1IntegrationB2BResultCheck(
  org: string,
  divCode: string,
  uid: string,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: V1B2BResultCheckResponse;
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(`/v1/i/result/${org}/${divCode}/${uid}`, {
    ...opts,
  });
}
/**
 * 사용자 초대 등록
 */
export function v1AttendeeRegister(
  v1AttendeeRegisterRequest: V1AttendeeRegisterRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse & {
          data?: V1AttendeeRegisterResponse;
        };
      }
    | {
        status: 400;
        data: GenericResponse;
      }
  >(
    "/v1/attendee/register",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: v1AttendeeRegisterRequest,
    })
  );
}
/**
 * 회원 탈퇴 및 사유 기록
 */
export function v1UserServiceWithdraw(
  v1ServiceReviewCollectRequest?: V1ServiceReviewCollectRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: V1ServiceReviewCollectRequest;
      }
    | {
        status: 400;
        data: GenericResponse;
      }
  >(
    "/v1/service/withdraw.do",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: v1ServiceReviewCollectRequest,
    })
  );
}
/**
 * get service json
 */
export function v1ServiceJson(
  pCate: string,
  agentType: string,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: V1ServiceJsonResponse;
      }
    | {
        status: 400;
        data: GenericResponse;
      }
  >(
    `/v1/service/json.do${QS.query(
      QS.form({
        pCate,
        agentType,
      })
    )}`,
    {
      ...opts,
    }
  );
}
/**
 * get service json
 */
export function v1ServiceNoAuthJson(
  pCate: string,
  agentType: string,
  lCode: string,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse & {
          data?: V1ServiceJsonResponse;
        };
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/service/noauth/json.do${QS.query(
      QS.form({
        pCate,
        agentType,
        lCode,
      })
    )}`,
    {
      ...opts,
    }
  );
}
/**
 * 적응 스트레스 사건 목록 조회
 */
export function v1ServiceNoAuthJsonAdnm4EventsList(
  pCate: string,
  agentType: string,
  lCode: string,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse & {
          data?: V1ServiceJsonAdnm4EventsListResponse;
        };
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    `/v1/service/noauth/json/adnm4/events${QS.query(
      QS.form({
        pCate,
        agentType,
        lCode,
      })
    )}`,
    {
      ...opts,
    }
  );
}
/**
 * ncs 코드, 이름 조회
 */
export function v1NcsJson(opts?: Oazapfts.RequestOpts) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse & {
          data?: V1NcsJsonResponse;
        };
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >("/v1/ncs/json.do", {
    ...opts,
  });
}
/**
 * 공지사항(팝업) 조회
 */
export function v1NoticeEmergency(opts?: Oazapfts.RequestOpts) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse & {
          data?: V1NoticeEmergencyResponse;
        };
      }
    | {
        status: 400;
        data: GenericResponse;
      }
  >("/v1/notice/emergency.do", {
    ...opts,
  });
}
/**
 * main 화면 정보 조회
 */
export function v1MainInfo(
  v1MainInfoRequest: V1MainInfoRequest,
  opts?: Oazapfts.RequestOpts
) {
  return oazapfts.fetchJson<
    | {
        status: 200;
        data: GenericResponse & {
          data?: V1MainInfoResponse;
        };
      }
    | {
        status: 418;
        data: GenericResponse;
      }
  >(
    "/v1/main/info.do",
    oazapfts.json({
      ...opts,
      method: "POST",
      body: v1MainInfoRequest,
    })
  );
}
