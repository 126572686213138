import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { iconValues } from "@/values/iconValues";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    values: iconValues,
    iconfont: "mdi",
  },
  theme: {
    themes: {
      light: {
        primary: "#005FB1",
        secondary: "#3789FF",
        tertiary: "#777777",
      },
    },
  },
});
