export const firebaseConfigDev = {
  apiKey: process.env.VUE_APP_FIREBASE_CONFIG_API_KEY_DEV,
  authDomain: "haii-d-prj-tess.firebaseapp.com",
  projectId: "haii-d-prj-tess",
  storageBucket: "haii-d-prj-tess.appspot.com",
  messagingSenderId: "587234296171",
  appId: "1:587234296171:web:bb5f1c556017c5b8570c2e",
  measurementId: "G-XYC51490NG",
};

export const firebaseConfigProd = {
  apiKey: process.env.VUE_APP_FIREBASE_CONFIG_API_KEY_PROD,
  authDomain: "haii-p-prj-tess.firebaseapp.com",
  projectId: "haii-p-prj-tess",
  storageBucket: "haii-p-prj-tess.appspot.com",
  messagingSenderId: "284926060251",
  appId: "1:284926060251:web:2f2c6842e7c6a6f26fcefe",
  measurementId: "G-SE2RWH9N2B",
};
