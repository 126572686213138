import { RootState, state } from "@/store/state";
import { MutationTree } from "vuex";
import { TLicenseAppendix } from "@/types/licenseTypes";
import {
  V1AssessmentData,
  V1ServiceJsonAdnm4EventsListResponse,
} from "@/generated/api";

enum MutationTypes {
  CHANGE_LOADING_STATE = "CHANGE_LOADING_STATE",
  CHANGE_SEND_DATA_STATE = "CHANGE_SEND_DATA_STATE",
  AUTH_SET = "AUTH_SET",
  PREVENT_CLOSE_SET = "PREVENT_CLOSE_SET",
  PREVENT_GO_BACK_SET = "PREVENT_GO_BACK_SET",
  LICENSE_ICODE_SET = "LICENSE_ICODE_SET",
  LICENSE_LCODE_SET = "LICENSE_LCODE_SET",
  LICENSE_AGENT_TYPE_SET = "LICENSE_AGENT_TYPE_SET",
  LICENSE_APPENDIX_SET = "LICENSE_APPENDIX_SET",
  EMERGENCY_CHECKED_SET = "EMERGENCY_CHECKED_SET",
  IS_EMERGENCY_SET = "IS_EMERGENCY_SET",
  EMERGENCY_IGNORE_UID_SET = "EMERGENCY_IGNORE_UID_SET",
  EMERGENCY_IS_EXCEPTIONAL_SET = "EMERGENCY_IS_EXCEPTIONAL_SET",
  USER_NCS_SET = "USER_NCS_SET",
  USER_NICKNAME_SET = "USER_NICKNAME_SET",
  USER_SF_SET = "USER_SF_SET",
  USER_JOB_CATEGORY_SET = "USER_JOB_CATEGORY_SET",
  TOTAL_STEP_SET = "TOTAL_STEP_SET",
  CURRENT_STEP_SET = "CURRENT_STEP_SET",
  RESET_TOTAL_STEP_SET = "RESET_TOTAL_STEP_SET",
  RESET_CURRENT_STEP_SET = "RESET_CURRENT_STEP_SET",
  CHAT_CONTENT_SET = "CHAT_CONTENT_SET",
  CSS_SCREENING_SET = "CSS_SCREENING_SET",
  CHAT_ASSESSMENT_SET = "CHAT_ASSESSMENT_SET",
  RESET_ASSESSMENT_SET = "RESET_ASSESSMENT_SET",
  RESET_ALL_DATA_SET = "RESET_ALL_DATA_SET",
  ADNM4_EVENT_LIST_SET = "ADNM4_EVENT_LIST_SET",
}

const mutations: MutationTree<typeof state> = {
  [MutationTypes.CHANGE_LOADING_STATE](
    state: RootState,
    loading: boolean
  ): void {
    state.loading = loading;
  },
  [MutationTypes.CHANGE_SEND_DATA_STATE](
    state: RootState,
    sendData: boolean
  ): void {
    state.sendData = sendData;
  },
  [MutationTypes.AUTH_SET](state: RootState, auth: string): void {
    state.auth = auth;
  },
  [MutationTypes.PREVENT_CLOSE_SET](
    state: RootState,
    preventClose: boolean
  ): void {
    state.preventClose = preventClose;
    state.isRefresh = preventClose;
  },
  [MutationTypes.LICENSE_ICODE_SET](state: RootState, iCode: string): void {
    state.license.iCode = iCode;
  },
  [MutationTypes.LICENSE_LCODE_SET](state: RootState, lCode: string): void {
    state.license.lCode = lCode;
  },
  [MutationTypes.LICENSE_AGENT_TYPE_SET](
    state: RootState,
    agentType: string
  ): void {
    state.license.agentType = agentType;
  },
  [MutationTypes.LICENSE_APPENDIX_SET](
    state: RootState,
    licenseAppendix: TLicenseAppendix
  ): void {
    state.license.licenseAppendix = licenseAppendix;
  },
  [MutationTypes.EMERGENCY_CHECKED_SET](
    state: RootState,
    isChecked: boolean
  ): void {
    state.emergency.isChecked = isChecked;
  },
  [MutationTypes.IS_EMERGENCY_SET](
    state: RootState,
    isEmergency: boolean
  ): void {
    state.emergency.isEmergency = isEmergency;
  },
  [MutationTypes.EMERGENCY_IGNORE_UID_SET](
    state: RootState,
    ignoreUid: string
  ): void {
    state.emergency.ignoreUid = ignoreUid;
  },
  [MutationTypes.EMERGENCY_IS_EXCEPTIONAL_SET](
    state: RootState,
    isExceptional: boolean
  ): void {
    state.emergency.isExceptional = isExceptional;
  },
  [MutationTypes.USER_NCS_SET](state: RootState, ncs: boolean): void {
    state.userInfo.ncs = ncs;
  },
  [MutationTypes.USER_NICKNAME_SET](state: RootState, nickName: string): void {
    state.userInfo.nickName = nickName;
  },
  [MutationTypes.USER_SF_SET](
    state: RootState,
    sf: {
      gender: number;
      birthyear: number;
      birthmonth: number;
      pCate: string;
      sCate: string;
      location: string;
      ages: string;
    }
  ): void {
    state.userInfo.sf = sf;
  },
  [MutationTypes.USER_JOB_CATEGORY_SET](
    state: RootState,
    jobCategory: {
      first: string;
      second: string;
      third: string;
    }
  ): void {
    state.userInfo.jobCategory = jobCategory;
  },
  [MutationTypes.TOTAL_STEP_SET](state: RootState, totalSteps: number): void {
    state.stepInfo.totalSteps += totalSteps;
  },
  [MutationTypes.CURRENT_STEP_SET](
    state: RootState,
    currentStep: number
  ): void {
    state.stepInfo.currentStep += currentStep;
  },
  [MutationTypes.RESET_TOTAL_STEP_SET](state: RootState): void {
    state.stepInfo.totalSteps = 0;
  },
  [MutationTypes.RESET_CURRENT_STEP_SET](state: RootState): void {
    state.stepInfo.currentStep = 0;
  },
  [MutationTypes.CHAT_CONTENT_SET](state: RootState, content: object): void {
    state.chat.content = { ...content };
  },
  [MutationTypes.CSS_SCREENING_SET](state: RootState, css: boolean): void {
    state.chat.cssScreening = css;
  },
  [MutationTypes.CHAT_ASSESSMENT_SET](
    state: RootState,
    assessment: {
      [key: string]: V1AssessmentData;
    }
  ): void {
    state.chat.assessment = { ...state.chat.assessment, ...assessment };
  },
  [MutationTypes.RESET_ASSESSMENT_SET](state: RootState): void {
    state.chat.assessment = {};
  },
  [MutationTypes.RESET_ALL_DATA_SET](state: RootState): void {
    (state.preventClose = false),
      (state.isRefresh = false),
      (state.preventGoBack = false),
      (state.license = {
        iCode: "",
        lCode: "",
        agentType: "",
        licenseAppendix: {
          brthdd: "",
          cmpycd: "",
          cmpyzipaddr: "",
          cmpyzipcd: "",
          email: "",
          hngnm: "",
          instcd: "",
          instnm: "",
          mpphontel: "",
          orddd: "",
          rgstno: "",
          sex: "",
        },
      }),
      (state.userInfo = {
        ncs: true,
        nickName: "",
        sf: {
          gender: -1,
          birthyear: -1,
          birthmonth: -1,
          pCate: "",
          sCate: "",
          location: "",
          ages: "",
        },
        jobCategory: {
          first: "",
          second: "",
          third: "",
        },
      }),
      (state.stepInfo = { totalSteps: 0, currentStep: 0 }),
      (state.chat = {
        content: {},
        cssScreening: false,
        assessment: {},
      });
  },
  [MutationTypes.ADNM4_EVENT_LIST_SET](
    state: RootState,
    eventList: V1ServiceJsonAdnm4EventsListResponse
  ): void {
    state.adnm4EventList = eventList;
  },
};

type Mutations = typeof mutations;

export { MutationTypes, mutations, Mutations };
