import Vue, { VueConstructor } from "vue";
import { ExServerClass, WebServerClass } from "@/plugins/server";
import util from "@/plugins/util";
import { ToastOptions, ToastType } from "vue-toasted";
import { MutationTypes } from "@/store/mutations";
import ChannelService from "@/plugins/channelTalk";

export interface IHaii {
  exServer: ExServerClass;
  webServer: WebServerClass;
  util: typeof util;
  channelTalk: typeof ChannelService;
}

declare module "vue/types/vue" {
  interface Vue {
    exServer: ExServerClass;
    webServer: WebServerClass;
    $_haii: IHaii;
    $_toastMessage: (
      icon: ToastType,
      message: string,
      timer?: number,
      option?: ToastOptions
    ) => void;
    $_changeLoadingState: (state: boolean) => void;
  }
}

interface addToastOptionsType extends ToastOptions {
  keepOnHover?: boolean;
}

export default {
  install: (v: VueConstructor): void => {
    v.prototype.$_haii = {
      exServer: new ExServerClass(),
      webServer: new WebServerClass(),
      util: util,
      channelTalk: ChannelService,
    };

    v.mixin({
      methods: {
        $_toastMessage(
          icon: ToastType,
          message: string,
          timer?: number,
          option?: addToastOptionsType
        ) {
          // 20글자 마다 줄바꿈
          message = (message + "").replace(/(.{20})/g, "$1\n");
          switch (icon) {
            case "success": {
              this.$toasted.success(
                message,
                option
                  ? option
                  : {
                      icon: "mdi-check-circle-outline",
                      duration: timer ? timer : 3000,
                      keepOnHover: true,
                      position: "top-center",
                    }
              );

              break;
            }
            case "error": {
              this.$toasted.error(
                message,
                option
                  ? option
                  : {
                      icon: "mdi-alert-outline",
                      duration: timer ? timer : 3000,
                      keepOnHover: true,
                      position: "top-center",
                    }
              );
              break;
            }
            case "info": {
              this.$toasted.info(
                message,
                option
                  ? option
                  : {
                      icon: "mdi-information-outline",
                      duration: timer ? timer : 3000,
                      keepOnHover: true,
                      position: "top-center",
                    }
              );
              break;
            }
            default: {
              this.$toasted.show("에러");

              break;
            }
          }
        },
        $_changeLoadingState(state: boolean) {
          return this.$store.commit(MutationTypes.CHANGE_LOADING_STATE, state);
        },
      },
    });
  },
};
