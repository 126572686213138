import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import store from "@/store";
import { MutationTypes } from "@/store/mutations";
import { mittUse } from "@/plugins/mitt";

Vue.use(VueRouter);

const isRefresh = (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
  if (store.getters.getPreventClose === store.getters.getIsRefresh) {
    next();

    return;
  }
  next("/main");
};

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "TessWeb",
    component: () =>
      import(
        /* webpackChunkName: "layouts-default-index" */
        "@/components/common/defaultLayout/DefaultLayout.vue"
      ),
    children: [
      {
        path: "",
        name: "Splash",
        component: () =>
          import(
            /* webpackChunkName: "splash" */
            "@/views/Splash.vue"
          ),
        meta: {
          auth: false,
          headers: [],
          secondary: true,
        },
      },
      {
        path: "method",
        name: "HrvChoice",
        component: () =>
          import(
            /* webpackChunkName: "examine-method" */
            "@/views/ExamineMethod.vue"
          ),
        meta: {
          auth: true,
          headers: ["help"],
          black: true,
        },
      },
      {
        path: "app",
        name: "HrvOnGuide",
        component: () =>
          import(
            /* webpackChunkName: "app-download-guide" */
            "@/views/AppDownloadGuide.vue"
          ),
        meta: {
          auth: true,
          headers: ["back", "help"],
          black: true,
        },
      },
      {
        path: "agreement",
        name: "AppAgreement",
        component: () =>
          import(
            /* webpackChunkName: "agreement" */
            "@/views/Agreement.vue"
          ),
        meta: {
          auth: true,
          headers: [],
          black: true,
        },
      },
      {
        path: "user-info",
        name: "UserInformation",
        component: () =>
          import(
            /* webpackChunkName: "user-info" */
            "@/views/UserInfo.vue"
          ),
        meta: {
          auth: true,
          headers: ["back", "help"],
          black: true,
        },
      },
      {
        path: "job-category",
        name: "JobCategory",
        component: () =>
          import(
            /* webpackChunkName: "job-category" */
            "@/views/JobCategory.vue"
          ),
        meta: {
          auth: true,
          headers: ["back", "help"],
          black: true,
        },
      },
      {
        path: "nickname",
        name: "NicknameSettings",
        component: () =>
          import(
            /* webpackChunkName: "setting-nickname" */
            "@/views/SettingNickname.vue"
          ),
        meta: {
          auth: true,
          headers: ["back", "help"],
          black: true,
        },
      },
      {
        path: "main",
        name: "Main",
        component: () =>
          import(
            /* webpackChunkName: "main" */
            "@/views/Main.vue"
          ),
        meta: {
          auth: true,
          headers: ["help"],
          black: false,
          secondary: true,
        },
      },
      {
        path: "before-examination",
        name: "AuthorizationGuide",
        component: () =>
          import(
            /* webpackChunkName: "before-examination" */
            "@/views/BeforeExaminationDialog.vue"
          ),
        meta: {
          auth: true,
          headers: [],
        },
      },
      {
        path: "examination-setting",
        name: "ExaminationSetting",
        component: () =>
          import(
            /* webpackChunkName: "examination-setting" */
            "@/views/chat/ExaminationSetting.vue"
          ),
        meta: {
          auth: true,
          headers: ["help", "close"],
          black: true,
          chatFlag: true,
        },
        beforeEnter: isRefresh,
      },
      {
        path: "koss",
        name: "KOSS",
        component: () =>
          import(
            /* webpackChunkName: "koss" */
            "@/views/chat/Koss.vue"
          ),
        meta: {
          auth: true,
          headers: ["stepper", "help", "close"],
          black: true,
          chatFlag: true,
        },
        beforeEnter: isRefresh,
      },
      {
        path: "phq-9",
        name: "PHQ-9",
        component: () =>
          import(
            /* webpackChunkName: "phq-9" */
            "@/views/chat/Phq.vue"
          ),
        meta: {
          auth: true,
          headers: ["stepper", "help", "close"],
          black: true,
          chatFlag: true,
        },
        beforeEnter: isRefresh,
      },
      {
        path: "gad-7",
        name: "GAD-7",
        component: () =>
          import(
            /* webpackChunkName: "gad-7" */
            "@/views/chat/Gad.vue"
          ),
        meta: {
          auth: true,
          headers: ["stepper", "help", "close"],
          black: true,
          chatFlag: true,
        },
        beforeEnter: isRefresh,
      },
      {
        path: "adnm-4",
        name: "ADNM-4",
        component: () =>
          import(
            /* webpackChunkName: "adnm-4" */
            "@/views/chat/Adnm.vue"
          ),
        meta: {
          auth: true,
          headers: ["stepper", "help", "close"],
          black: true,
          chatFlag: true,
        },
        beforeEnter: isRefresh,
      },
      {
        path: "pc-ptsd-5",
        name: "PC-PTSD-5",
        component: () =>
          import(
            /* webpackChunkName: "pc-ptsd-5" */
            "@/views/chat/Ptsd.vue"
          ),
        meta: {
          auth: true,
          headers: ["stepper", "help", "close"],
          black: true,
          chatFlag: true,
        },
        beforeEnter: isRefresh,
      },
      {
        path: "isi-k",
        name: "ISI-K",
        component: () =>
          import(
            /* webpackChunkName: "isi-k" */
            "@/views/chat/Isik.vue"
          ),
        meta: {
          auth: true,
          headers: ["stepper", "help", "close"],
          black: true,
          chatFlag: true,
        },
        beforeEnter: isRefresh,
      },
      {
        path: "css",
        name: "CSS",
        component: () =>
          import(
            /* webpackChunkName: "css" */
            "@/views/chat/Css.vue"
          ),
        meta: {
          auth: true,
          headers: ["stepper", "help", "close"],
          black: true,
          chatFlag: true,
        },
        beforeEnter: isRefresh,
      },
      {
        path: "outro",
        name: "Outro",
        component: () =>
          import(
            /* webpackChunkName: "outro" */
            "@/views/chat/Outro.vue"
          ),
        meta: {
          auth: true,
          headers: ["help", "close"],
          black: true,
          chatFlag: true,
        },
        beforeEnter: isRefresh,
      },
      {
        path: ":organization/:registerNum/:examinationDate?/:instituteCode?",
        name: "Splash",
        component: () =>
          import(
            /* webpackChunkName: "splash" */
            "@/views/Splash.vue"
          ),
        meta: {
          auth: false,
          headers: [],
          secondary: true,
        },
        props: true,
      },
      {
        path: "404",
        name: "NotFound",
        component: () =>
          import(
            /* webpackChunkName: "notFound" */
            "@/views/NotFound.vue"
          ),
        meta: {
          auth: false,
          headers: [],
        },
      },
    ],
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export const viewComponentType = {
  CHECK_EMERGENCY_FINISH_SPLASH: "checkEmergencyFinishedSplash",
  CHECK_EMERGENCY_FINISH_MAIN: "checkEmergencyFinishedMain",
  CHECK_EMERGENCY_FINISH_AUTHORIZATION_GUIDE:
    "checkEmergencyFinishedAuthorizationGuide",
  CHECK_EMERGENCY_FINISH_EXAMINATION_SETTING:
    "checkEmergencyFinishedExaminationSetting",
  CHECK_EMERGENCY_FINISH_KOSS: "checkEmergencyFinishedKoss",
  CHECK_EMERGENCY_FINISH_PHQ_9: "checkEmergencyFinishedPHQ-9",
  CHECK_EMERGENCY_FINISH_GAD_7: "checkEmergencyFinishedGAD-7",
  CHECK_EMERGENCY_FINISH_ADNM_4: "checkEmergencyFinishedADNM-4",
  CHECK_EMERGENCY_FINISH_PC_PTSD_5: "checkEmergencyFinishedPC-PTSD-5",
  CHECK_EMERGENCY_FINISH_ISI_K: "checkEmergencyFinishedISI-K",
  CHECK_EMERGENCY_FINISH_CSS: "checkEmergencyFinishedCSS",
  CHECK_EMERGENCY_FINISH_OUTRO: "checkEmergencyFinishedOutro",
} as const;

export type TViewComponent = keyof typeof viewComponentType;

router.beforeEach(async (to, from, next) => {
  store.commit(MutationTypes.EMERGENCY_CHECKED_SET, false);
  mittUse.off("agree");
  mittUse.off("next");
  mittUse.off("toMainFromChat");
  mittUse.off("goBack");
  mittUse.off(viewComponentType.CHECK_EMERGENCY_FINISH_SPLASH);
  mittUse.off(viewComponentType.CHECK_EMERGENCY_FINISH_MAIN);
  mittUse.off(viewComponentType.CHECK_EMERGENCY_FINISH_AUTHORIZATION_GUIDE);
  mittUse.off(viewComponentType.CHECK_EMERGENCY_FINISH_EXAMINATION_SETTING);
  mittUse.off(viewComponentType.CHECK_EMERGENCY_FINISH_KOSS);
  mittUse.off(viewComponentType.CHECK_EMERGENCY_FINISH_PHQ_9);
  mittUse.off(viewComponentType.CHECK_EMERGENCY_FINISH_GAD_7);
  mittUse.off(viewComponentType.CHECK_EMERGENCY_FINISH_ADNM_4);
  mittUse.off(viewComponentType.CHECK_EMERGENCY_FINISH_PC_PTSD_5);
  mittUse.off(viewComponentType.CHECK_EMERGENCY_FINISH_ISI_K);
  mittUse.off(viewComponentType.CHECK_EMERGENCY_FINISH_CSS);
  mittUse.off(viewComponentType.CHECK_EMERGENCY_FINISH_OUTRO);
  next();

  const auth = to.meta?.auth;

  // url 접근 가능
  if (!auth) return;

  // url 접근 불가능
  // 권한이 있는 경우
  if (to.path === store.getters.getAuth) return;

  // 권한이 없는 경우
  store.commit(MutationTypes.AUTH_SET, "");
  await router.replace("/404");
});

export default router;
