import { ActionTree } from "vuex";
import { state, RootState } from "@/store/state";

enum ActionTypes {}

const actions: ActionTree<typeof state, RootState> = {};

type Actions = typeof actions;

export { ActionTypes, actions, Actions };
