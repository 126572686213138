import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import { RootState, state } from "./state";
import { getters } from "./getters";
import { mutations, MutationTypes } from "./mutations";
import { actions } from "./actions";

Vue.use(Vuex);

// 새로고침을 해도 데이터를 유지하기 위함
const vuexSession = new VuexPersistence({
  storage: window.sessionStorage,
  // state 중 유지할 데이터만 선별
  reducer: (state: RootState) => ({
    auth: state.auth,
    license: state.license,
    userInfo: state.userInfo,
    preventClose: state.preventClose,
  }),

  // mutation 중 변경사항 반영할 state 가 존재하는 경우, 만약 기본적으론 모든 mutation이 true 로 반영
  filter: (mutations: any): boolean => {
    switch (mutations.type) {
      case MutationTypes.AUTH_SET:
        return true;
      case MutationTypes.PREVENT_CLOSE_SET:
        return true;
      case MutationTypes.LICENSE_ICODE_SET:
        return true;
      case MutationTypes.LICENSE_LCODE_SET:
        return true;
      case MutationTypes.LICENSE_AGENT_TYPE_SET:
        return true;
      case MutationTypes.LICENSE_APPENDIX_SET:
        return true;
      case MutationTypes.USER_NCS_SET:
        return true;
      case MutationTypes.USER_NICKNAME_SET:
        return true;
      case MutationTypes.USER_SF_SET:
        return true;
      case MutationTypes.USER_JOB_CATEGORY_SET:
        return true;
      default:
        break;
    }
    return false;
  },
});

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,

  reducer: (state: RootState) => ({
    emergency: state.emergency,
  }),

  // mutation 중 변경사항 반영할 state 가 존재하는 경우, 만약 기본적으론 모든 mutation이 true 로 반영
  filter: (mutations): boolean => {
    switch (mutations.type) {
      case MutationTypes.EMERGENCY_IGNORE_UID_SET:
        return true;
      default:
        break;
    }
    return false;
  },
});

export default new Vuex.Store({
  plugins: [vuexSession.plugin, vuexLocal.plugin],
  state,
  getters,
  mutations,
  actions,
});
