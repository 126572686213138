import { TLicenseAppendix } from "@/types/licenseTypes";
import {
  V1AssessmentData,
  V1ServiceJsonAdnm4EventsListResponse,
} from "@/generated/api";

interface RootState {
  loading: boolean;
  sendData: boolean;
  auth: string;
  preventClose: boolean;
  isRefresh: boolean;
  preventGoBack: boolean;
  license: {
    iCode: string;
    lCode: string;
    agentType: string;
    licenseAppendix: TLicenseAppendix;
  };
  emergency: {
    isChecked: boolean;
    isEmergency: boolean;
    ignoreUid: string;
    isExceptional: boolean;
  };
  userInfo: {
    ncs: boolean;
    nickName: string;
    sf: {
      gender: number;
      birthyear: number;
      birthmonth: number;
      pCate: string;
      sCate: string;
      location: string;
      ages: string;
    };
    jobCategory: {
      first: string;
      second: string;
      third: string;
    };
  };
  stepInfo: { totalSteps: number; currentStep: number };
  chat: {
    content: object;
    cssScreening: boolean;
    assessment: {
      [key: string]: V1AssessmentData;
    };
  };
  adnm4EventList: V1ServiceJsonAdnm4EventsListResponse;
}

const state: RootState = {
  loading: false,
  sendData: false,
  auth: "",
  preventClose: false,
  isRefresh: false,
  preventGoBack: false,
  license: {
    iCode: "",
    lCode: "",
    agentType: "",
    licenseAppendix: {
      brthdd: "",
      cmpycd: "",
      cmpyzipaddr: "",
      cmpyzipcd: "",
      email: "",
      hngnm: "",
      instcd: "",
      instnm: "",
      mpphontel: "",
      orddd: "",
      rgstno: "",
      sex: "",
    },
  },
  emergency: {
    isChecked: false,
    isEmergency: false,
    ignoreUid: "",
    isExceptional: false,
  },
  userInfo: {
    ncs: true,
    nickName: "",
    sf: {
      gender: -1,
      birthyear: -1,
      birthmonth: -1,
      pCate: "",
      sCate: "",
      location: "",
      ages: "",
    },
    jobCategory: {
      first: "",
      second: "",
      third: "",
    },
  },
  stepInfo: { totalSteps: 0, currentStep: 0 },
  chat: {
    content: {},
    cssScreening: false,
    assessment: {},
  },
  adnm4EventList: [],
};

export { state, RootState };
