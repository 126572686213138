import backWhite from "@/assets/icons/header/backWhite.vue";
import backBlack from "@/assets/icons/header/backBlack.vue";
import closeWhite from "@/assets/icons/header/closeWhite.vue";
import closeBlack from "@/assets/icons/header/closeBlack.vue";
import helpBlack from "@/assets/icons/header/helpBlack.vue";
import helpWhite from "@/assets/icons/header/helpWhite.vue";
import settingWhite from "@/assets/icons/header/settingWhite.vue";
import settingBlack from "@/assets/icons/header/settingBlack.vue";
import tessLogo from "@/assets/icons/TessLogo.vue";
import tessTitle from "@/assets/icons/TessTitle.vue";
import simpleExamination from "@/assets/icons/SimpleExamination.vue";
import hrvExamination from "@/assets/icons/HrvExamination.vue";
import googlePlayStore from "@/assets/icons/GooglePlayStore.vue";
import appleAppStore from "@/assets/icons/AppleAppStore.vue";
import tessProfile from "@/assets/icons/TessProfile.vue";

export const iconValues = {
  BackWhite: {
    component: backWhite,
  },
  BackBlack: {
    component: backBlack,
  },
  CloseWhite: {
    component: closeWhite,
  },
  CloseBlack: {
    component: closeBlack,
  },
  SettingWhite: {
    component: settingWhite,
  },
  SettingBlack: {
    component: settingBlack,
  },
  HelpWhite: {
    component: helpWhite,
  },
  HelpBlack: {
    component: helpBlack,
  },
  TessTitle: {
    component: tessTitle,
  },
  TessLogo: {
    component: tessLogo,
  },
  SimpleExamination: {
    component: simpleExamination,
  },
  HrvExamination: {
    component: hrvExamination,
  },
  GooglePlayStore: {
    component: googlePlayStore,
  },
  AppleAppStore: {
    component: appleAppStore,
  },
  TessProfile: {
    component: tessProfile,
  },
};
